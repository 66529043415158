import React, { useState, useEffect } from 'react';
import './new.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { requestOTPAction, loginAction } from '../redux/actions/loginAction';
import { allValidations } from '../utils/formValidations';
import { showFormErrors } from '../utils/commonFunctions';
import { PrimaryButtonOutlined } from '../shared/Button/PrimaryButton';
import CrossIcon from "../assets/icons/cross-icon.svg";

export default function LoginSidebar({ isOpen, onClose }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [data, setData] = useState({
        countryCode: '+91',
        mobile: '',
        errorMessage: '',
    });
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);

    // Countdown timer for Resend OTP
    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    const handleChange = ({ target: { name, value } }) => {
        if (name === 'mobile') {
            value = value.replace(/^(\+91\s?)|[^0-9]/g, '').slice(0, 10);
        }
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleOtpChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setOtp(value);
    };

    const handleResendOtp = async () => {
        if (timer === 0) {
            await dispatch(requestOTPAction(data, setLoading, setOtpSent));
            setTimer(30);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            try {
                if (otpSent) {
                    await dispatch(loginAction({ ...data, otpCode: otp }, setLoading, onClose, navigate));
                } else {
                    await handleResendOtp();
                }
            } catch (error) {
                console.error('Login or OTP resend error:', error);
            }
        }
    };

    return (
        <div className={`login__sidebar-overlay  ${isOpen ? 'open' : ''}`}>
            <div className="login__sidebar py-6 md:p-7">
                <button className="login__close-btn" onClick={onClose}>
                    <img src={CrossIcon} alt="" />
                </button>
                <div className="login__content mt-5">
                    <h3 className="login__content__heading text-left">
                        {otpSent ? `We have sent a verification code to ${data.countryCode} ${data.mobile}` : "India's #1 Scan App where vehicle owners meet"}
                    </h3>
                    <form onSubmit={handleSubmit}>
                        {!otpSent ? (
                            <input
                                type="text" // Use text to handle non-numeric input gracefully
                                name="mobile"
                                value={data.mobile ? `+91 ${data.mobile}` : ''} // Display +91 prefix if mobile exists
                                onChange={handleChange}
                                placeholder="+91 Enter Mobile Number"
                                className="login__otp-input"
                                required
                            />
                        ) : (
                            // <input
                            //     type="number"
                            //     name="mobile"
                            //     // value={data.mobile}
                            //     value={`+91 ${data.mobile}`}
                            //     // value={`${data.countryCode} ${data.mobile}`}
                            //     onChange={handleChange}
                            //     placeholder="Enter Mobile Number"
                            //     className="login__otp-input"
                            //     required
                            // />
                            // <CustomInputMobileNumber
                            //   col="12"
                            //   data={data}
                            //   onChange={handleChange}
                            //   name="mobile"
                            //   label="Mobile Number"
                            //   required
                            //   extraClassName={"login__otp-input"}
                            //   //   className="login__otp-input"

                            //   errorMessage={data.errorMessage}
                            // />
                            <>
                                <input
                                    type="text"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    placeholder="Enter OTP"
                                    className="login__otp-input"
                                    maxLength={6}
                                    required
                                />
                            </>
                        )}
                        <PrimaryButtonOutlined label={otpSent ? 'Verify OTP' : 'Send OTP'} className="login__verify-btn" loading={loading} type="submit" />
                        {otpSent && (
                            <div className="login__resend">
                                <h6 className="mt-3 cursor-pointer" onClick={timer > 0 ? null : handleResendOtp}>
                                    {' '}
                                    Didn't get the OTP ? <span className={`${timer > 0 ? 'text-gray-100' : 'text-main'}`}>Resend OTP</span>{' '}
                                    {timer > 0 ? timer + 's' : ''}
                                </h6>
                            </div>
                        )}
                    </form>
                    <div className="login__footer">
                        <span className="login__footer__text">By continuing, you agree to our</span>
                        <br />
                        <Link to="/terms-and-condition" className="login__footer-link underline login__footer__text">
                            Terms of Service
                        </Link>
                        <Link to="/privacy-policy" className="login__footer-link underline login__footer__text">
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
