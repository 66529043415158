import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import LogoImg from '../../assets/images/logo.png';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { logout } from '../../services/auth';
import { getProfile } from '../../redux/actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';

export default function AuthNavbar() {
    const menuRight = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfile(navigate));
        //eslint-disable-next-line
    }, [dispatch]);

    const { user } = useSelector((state) => state?.user);

    const items = [
        {
            icon: 'pi pi-power-off',
            label: 'Logout',
            command: () => {
                logout(() => navigate(''));
            },
        },
    ];

    console.log('fullName>>', user);

    return (
        <nav className="authnavbar py-2">
            <div className="navbar__container">
                <div className="navbar__content">
                    <Link className="navbar__logo">
                        <img src={LogoImg} alt="" width={150} />
                    </Link>

                    <div className="navbar__user">
                        <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                        <div className="flex gap-2 align-items-center cursor-pointer" onClick={(event) => menuRight.current.toggle(event)}>
                            <Avatar icon="pi pi-user" className="border-circle" shape="circle" />
                            <div className="flex gap-2 m-2">
                                <span className="font-semibold w-auto max-w-7rem text-sm md:text-base  ">
                                    {user?.firstName} {user?.lastName || 'Loading...'}
                                    {/* {`${(user?.firstName || '').slice(0, 10)}${(user?.firstName?.length > 10 ? '...' : '')} `}
                                         {`${(user?.lastName || 'Loading...').slice(0, 5)}${(user?.lastName?.length > 10 ? '...' : '')}`} */}
                                </span>
                                <i className="pi pi-angle-down mt-1 px-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
