import { Skeleton } from 'primereact/skeleton';

export const SkeletonComponent = ({ times, size, width, shape, height }) => {
    const boxes = Array(times)
        .fill(0)
        .map((_, i) => {
            return <Skeleton key={i} size={`${size}rem`} width={`${width}rem`} height={`${height}rem}`} shape={shape} className="m-2"></Skeleton>;
        });

    return boxes;
};

export const SkeletonComp = ({ width, height, className }) => {
    console.log(width, height);
    return <Skeleton width={`${width}rem`} height={`${height}rem`} className={`mb-2 ${className}`}></Skeleton>;
};

export const SkeletonTable = () => {
    return (
        <>
            {Array(5)
                .fill()
                .map((item, index) => (
                    <Skeleton key={index} width="100%" height="4rem" className="mb-2" shape="rectangle"></Skeleton>
                ))}
        </>
    );
};
